import { useGetStatistika } from "../../hooks/query/useGetStatistika";
import { useTranslation } from "react-i18next";
import { MdOutlineMessage } from "react-icons/md";
import { GiProgression } from "react-icons/gi";
import { AiOutlineFileAdd } from "react-icons/ai";
import { BiMessageRoundedEdit } from "react-icons/bi";
import { AiOutlineSwitcher } from "react-icons/ai";

const Statistika = () => {
  const useGetStatistikaList = useGetStatistika();
  const { t } = useTranslation();
  return (
    <div
      className=" 
        mt-[30px]
        bg-[#eef2ff]
        "
    >
      <h3
        className=" 
            font-[700] 
            text-center 
            text-[30px] 
            text-#121221 
            pt-6
            "
      >
        {t("Murojaatlar statistikasi")}
      </h3>
      {useGetStatistikaList.data?.map((item) => (
        <div key={item.id}>
          <div
            className="
                   
                    h-[200px]    
                    rounded-xl
                    flex 
                    items-center
                    justify-between
                    px-8
                    text-[#0a2647]
                    "
          >
            <div
              className=" 
                        flex 
                        gap-4 
                        "
            >
              <div>
                <p
                  className="
                                text-[24px] 
                                font-bold
                                "
                >
                  {item.appeals}
                </p>
                <p>{t("Umumiy murojaatlar")}</p>
              </div>
              <MdOutlineMessage size={50} />
            </div>
            <div
              className=" 
                        flex 
                        gap-4 
                        "
            >
              <div>
                <p
                  className="
                                text-[24px] 
                                font-bold
                                "
                >
                  {item.looking_appeals}
                </p>
                <p>{t("Jarayonda")}</p>
              </div>
              <GiProgression size={50} />
            </div>
            <div
              className=" 
                        flex
                        gap-4 
                        "
            >
              <div>
                <p
                  className="
                                text-[24px] 
                                font-bold
                                "
                >
                  {item.expertiza}
                </p>
                <p>{t("Qanoatlantirildi")}</p>
              </div>
              <AiOutlineFileAdd size={50} />
            </div>
            <div
              className=" 
                        flex 
                        gap-4 
                        "
            >
              <div>
                <p
                  className="
                                text-[24px] 
                                font-bold
                                "
                >
                  {item.understand}
                </p>
                <p>{t("Tushuntirildi")}</p>
              </div>
              <BiMessageRoundedEdit size={50} />
            </div>
            <div
              className="
                        flex 
                        gap-4 
                        "
            >
              <div>
                <p
                  className="
                                text-[24px] 
                                font-bold
                                "
                >
                  {item.canceled}
                </p>
                <p>{t("Rad etildi")}</p>
              </div>
              <AiOutlineSwitcher size={50} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Statistika;
