import { useTranslation } from "react-i18next";
import oq from "../../assets/images/oq.jpg";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex justify-center items-center px-[100px] gap-8 h-[400px]">
        <div className="w-[600px]">
          <img src={oq} alt="" />
        </div>
        <div className="w-[865px] h-[170px]">
          <h4 className="font-[700] mb-[10px] mt-[-50px] text-[36px] text-#121221">
            {t("Oqqo‘rg‘on tumani haqida")}
          </h4>
          <p>
            <strong className="text-[20px]">Oqqo‘rg‘on tumani</strong>— Toshkent
            viloyatidagi tuman. 1935-yil 28-iyulda tashkil qilingan. Shimoliy va
            shimoli-gʻarbda Quyi Chirchiq tumani, gʻarb va janubi-gʻarbda
            Sirdaryo viloyati, shimoli-sharqda Oʻrta Chirchiq tumani, janub va
            janubi-sharqda Boʻka tumani, sharqda Piskent tumani bilan
            chegaradosh. Tumanda 1 shahar (Oqqoʻrgʻon), 1 shaharcha (Alimkent),
            10 qishloq fuqarolari yigʻini (Sultonobod, Achchi, Doʻstlik,
            Zarbdor, Zafar, Oytamgʻali, Oqqoʻrgʻon, Toshtoʻgʻon, Shohruxiya,
            Eltamgʻali, Erkinlik) bor. Tuman markazi — Oqqoʻrgʻon shahri.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegionInfo;
